<template>
  <div class="container">
    <div class="header">
      <img src="../assets/contact/tt2.png" alt="" />
      <div class="center">
        <img src="../assets/contact/gsjj.png" alt="" />
      </div>
      <div class="right">
        <img src="../assets/contact/kf.png" alt="" />
        <!-- <el-popover
                    v-for="(item,i) in contacts"
                    :key="i"
                    placement="left"
                    :title=item.title
                    width="200"
                    trigger="hover"
                    :content=item.content>
                    <img slot="reference" :src="item.img" alt="">
               
                </el-popover> -->
        <!-- <div>
          <img slot="reference" src="../assets/contact/cxkh.png" alt="" />
        </div> -->
        <el-popover placement="left" width="150" trigger="hover">
          <p style="color: #127bf2">18758021092</p>
          <p>客服热线</p>
          <img slot="reference" src="../assets/contact/lxwm.png" alt="" />
        </el-popover>
        <el-popover placement="left" width="200" trigger="hover">
          <div class="codeflex" style="display: flex">
            <div class="codeleft">
              <img style="width:100%" src="../assets/contact/qrcode.jpg" alt="" />
              <p style="color: #333333">官方微信服务号</p>
            </div>
            <div class="codeleft">
              <img style="width:100%"  src="../assets/contact/qrcode.jpg" alt="" />
              <p style="color: #333333">官方微信视频号</p>
            </div>
          </div>
          <img slot="reference" src="../assets/contact/WX.png" alt="" />
        </el-popover>
        <el-popover placement="left" width="200" trigger="hover">
          <p style="color: #127bf2">362995278@qq.com</p>
          <p>客服邮箱</p>
          <img slot="reference" src="../assets/contact/YX.png" alt="" />
        </el-popover>
        <div>
          <img @click="scrollToTop()" slot="reference" src="../assets/contact/ZD.png" alt="" />
        </div>
      </div>
      <div class="bottom">
        <div v-for="(item, i) in profile" :key="i">
          <div class="data">
            <span class="num">{{ item.num }}</span>
            <span class="unit">{{ item.unit }}</span>
          </div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">公司介绍</div>
      <div v-html="intro" class="description"></div>
      <div class="contact">
        <img src="../assets/contact/qrcode.jpg" alt="" />
        <div>关注微信公众号</div>
      </div>
      <div class="bg">
        <img src="../assets/contact/GST.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  data() {
    return {
      profile: [
        {
          num: 2023,
          unit: "年",
          desc: "成立年份",
        },
        {
          num: 10,
          unit: "个",
          desc: "业务范围",
        },
        {
          num: 16,
          unit: "名",
          desc: "公司员工",
        },
        {
          num: 160,
          unit: "万元",
          desc: "2023年年收入",
        },
      ],
      intro: `杭州字景数科电子有限公司位于杭州市未来科技城浙江数智引擎创新园内，紧邻西溪湿地，办公环境优雅，全面享受余杭产业政策。公司专业从事智能输配电产品的研发、生产、销售和服务，业务重点在三大领域：一、智能输配电及控制设备；二、智能仪器仪表；三、电子元器件。<br/>公司的核心是技术创新与服务，研发人员占比80%以上。大专、本科、硕士研究生梯级合理配置，充分发挥个人的想象力和创造力，保证所有产品均百分之百自主研发。 <br/>公司秉承“市场的需求就是我们创新的动力”企业文化，为输配电行业安全、便捷、节能贡献自己的一份力量。`,
      contacts: [
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/cxkh.png"),
        },
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/lxwm.png"),
        },
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/WX.png"),
        },
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/YX.png"),
        },
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/ZD.png"),
        },
      ],
    };
  },
  methods: {
    // 滚动到顶部
    scrollToTop() {
      // 滚动到页面顶部
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
}

.header > img {
  width: 100%;
  /* height: 80vh; */
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center img {
  width: 4.2rem;
  height: 2.8rem;
}

.right {
  position: fixed;
  right: 0.5%;
  top: 40%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;
}
.right img {
  width: 0.68rem;
  height: 0.68rem;
}
.right > img {
  width: 0.9rem;
  height: 0.9rem;
}

.bottom {
  width: 100%;
  height: 2rem;
  background: rgba(1, 21, 55, 0.6);
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
}
.num {
  font-size: 0.58rem;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  margin-right: 0.1rem;
}
.desc,
.unit {
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
}

.content {
  padding-left: 2.36rem;
  position: relative;
}

.title {
  font-size: 0.48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0a0a0a;
  padding: 0.47rem 0 0.39rem 0;
}

.description {
  width: 9.18rem;
  height: 2.84rem;
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #101010;
  line-height: 0.5rem;
  margin-bottom: 0.6rem;
}

.contact img {
  width: 2.24rem;
}
.contact div {
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #101010;
  letter-spacing: 4px;
  padding-bottom: 0.7rem;
}

.bg {
  width: 9.8rem;
  height: 6.86rem;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.bg img {
  width: 100%;
  height: 100%;
}
</style>
